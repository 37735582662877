<template>
  <el-dialog
    title="提示"
    v-model="dialogVisible"
    width="20%"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <div>兑换成功，请等待平台处理并发货!</div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="flush" size="default">好的</el-button>
    </span>
  </el-dialog>
</template>

<script setup>
import { onMounted, ref } from "vue";

const dialogVisible = ref(true);
const emits = defineEmits(["close"]);
import { useRouter } from "vue-router";

const router = useRouter();

const handleClose = () => {
  close();
};

onMounted(() => {
  setTimeout(() => {
    flush();
  }, 3000);
});

const close = () => {
  emits("close");
};

const isToPage=ref(true)  //避免重复跳转
const flush = () => {
  if (isToPage.value){
    router.push({
      path: "/pointsCenter",
    });
  }
  isToPage.value=false
};
</script>

<style scoped lang="less">
.dialog-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
</style>
